import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { keyframes } from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Bubble from "../../content/assets/llo-icon.svg"

const Main = styled.div`
  grid-area: image;
  .hallo {
    height: 100%;
    min-height: 20rem;
    position: relative;
    overflow: hidden;
  }
`
const Aside = styled.main`
  grid-area: main;
`
const glide = keyframes`
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5vh);
  }
  to {
    transform: translateY(0);
  }
`

const BubbleImg = styled.img`
  width: 10vw;
  max-width: 10rem;
  position: absolute;
  top: 20%;
  left: 50%;
  animation: ${glide} 4s infinite;
`
const Hallo = styled.span`
  position: absolute;
  bottom: 0rem;
  line-height: 1;
  color: white;
  font-size: 20vw;
  font-weight: 900;
  margin-left: -1.5vw;
`

export default function Template({ data }) {
  const { html, fields } = data.markdownRemark
  const fieldsObj = JSON.parse(fields.frontmatter)
  return (
    <Layout>
      <SEO
        title={fieldsObj.title}
        description={fieldsObj.description}
        article={fieldsObj.type === "post"}
        image={fieldsObj.image}
        pathname={fieldsObj.path}
      />
      <Main>
        <BackgroundImage
          Tag="div"
          className="hallo"
          fluid={data.desktop.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          <BubbleImg src={Bubble} alt="bubble" />
          <Hallo>Hallo</Hallo>
        </BackgroundImage>
      </Main>
      <Aside>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Aside>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      fields {
        frontmatter
      }
    }
    desktop: file(relativePath: { eq: "assets/hallo.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
